<template>
    <LiefengContent>
        <template v-slot:title>员工授权</template>
        <template v-slot:toolsbarRight>
            <Button style="margin-right: 10px" @click="addGrid" type="success" icon="ios-add">新增</Button>
        </template>
        <template v-slot:contentArea>
            <LiefengTable
                :talbeColumns="talbeColumns"
                :tableData="tableData"
                :loading="loading"
                :fixTable="true"
                :curPage="curPage"
                :total="total"
                :page-size="pageSize"
                :pagesizeOpts="[20, 30, 50, 100]"
                @hadlePageSize="hadlePageSize"
                @tableSelect="tableSelect"
                :hidePage="true"
            ></LiefengTable>

            <!--新增网格模态框-->
            <LiefengModal :title="'新增'" :width="'100%'" :value="addModel.addGridStatus" @input="addGridStatusFn" class="group" :fullscreen="false">
                <template v-slot:contentarea>
                    <Form :model="formData" label-position="left" :label-width="150">
                        <FormItem>
                            <span slot="label" class="validate">选择地区:</span>
                            <div class="cascader-div">
                                <Select
                                    v-model="formData.addr.provinceCode"
                                    transfer
                                    style="width: 150px; margin-right: 10px"
                                    :max-tag-count="1"
                                    placeholder="请选择省"
                                    filterable
                                    @on-change="changeProvince"
                                    :multiple="false"
                                >
                                    <Option :value="item.code" v-for="(item, index) in provinceList" :key="index">{{ item.label }}</Option>
                                </Select>
                                <Select
                                    v-model="formData.addr.cityCode"
                                    transfer
                                    style="width: 150px; margin-right: 10px"
                                    :max-tag-count="1"
                                    placeholder="请选择市"
                                    filterable
                                    :disabled="cityDisabled"
                                    @on-change="changeCity"
                                    :multiple="false"
                                >
                                    <Option :value="item.code" v-for="(item, index) in cityList" :key="index">{{ item.label }}</Option>
                                </Select>
                                <Select
                                    v-model="formData.addr.countyCode"
                                    transfer
                                    style="width: 150px; margin-right: 10px"
                                    :max-tag-count="1"
                                    placeholder="请选择区"
                                    :disabled="regionDisabled"
                                    filterable
                                    @on-change="changeRegion"
                                    :multiple="true"
                                >
                                    <Option :value="item.code" v-for="(item, index) in regionList" :key="index">{{ item.label }}</Option>
                                </Select>
                                <Select
                                    v-model="formData.addr.streetCode"
                                    transfer
                                    style="width: 150px; margin-right: 10px"
                                    :max-tag-count="1"
                                    placeholder="请选择街道/乡镇"
                                    :disabled="streeDisabled"
                                    filterable
                                    @on-change="changeStree"
                                    :multiple="true"
                                >
                                    <Option :value="item.code" v-for="(item, index) in streeList" :key="index">{{ item.label }}</Option>
                                </Select>
                                <Select
                                    v-model="formData.addr.communityCode"
                                    transfer
                                    style="width: 150px"
                                    :max-tag-count="1"
                                    placeholder="请选择社区"
                                    :disabled="projectDisabled"
                                    :multiple="true"
                                    filterable
                                >
                                    <Option :value="item.code" v-for="(item, index) in projectList" :key="index">{{ item.label }}</Option>
                                </Select>
                            </div>
                        </FormItem>
                    </Form>
                </template>
                <template v-slot:toolsbar>
                    <Button
                        type="info"
                        style="margin-right: 10px"
                        @click="
                            addModel.cancelGridTip = true
                            addGridStatusFn(false)
                        "
                    >
                        取消
                    </Button>
                    <Button type="primary" @click="saveGrid">确定</Button>
                </template>
            </LiefengModal>
        </template>
    </LiefengContent>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengModal from "@/components/LiefengModal"
import LiefengTable from "@/components/LiefengTable"
import proxy from "@/api/proxy.js"
export default {
    components: {
        LiefengContent,
        LiefengModal,
        LiefengTable,
    },
    data() {
        return {
            loading: false,
            curPage: 0,
            total: 0,
            pageSize: 20,

            tableData: [],
            talbeColumns: [
                {
                    title: "授权范围",
                    minWidth: 300,
                    key: "orgName",
                    align: "center",
                },
                {
                    title: "操作",
                    width: 100,
                    align: "center",
                    render: (h, params) => {
                        return h(
                            "Button",
                            {
                                props: {
                                    type: "primary",
                                    size: "small",
                                },
                                on: {
                                    click: () => {
                                        this.$Modal.confirm({
                                            title: "删除选项",
                                            content: "删除后无法恢复，是否继续删除？",
                                            okText: "确认删除",
                                            cancelText: "取消",
                                            onOk: () => {
                                                this.deleteStafforgScope(params.row.orgCode)
                                            },
                                        })
                                    },
                                },
                            },
                            "删除"
                        )
                    },
                },
            ],
            addModel: {
                addGridStatus: false,
                cancelGridTip: false,
            },

            custGlobalId: this.$route.query.custGlobalId,
            orgCodeSelf: this.$route.query.orgCodeSelf,

            //   省
            province: "",
            provinceList: [], //存放城市列表
            // 市
            city: "",
            cityDisabled: true,
            cityList: [], //存放城市列表
            //  区
            region: "",
            regionDisabled: true,
            regionList: [], // 存放区的列表
            //   街道
            stree: "",
            streeDisabled: true,
            streeList: [], //存放街道的列表

            // 区
            project: "",
            projectDisabled: true,
            projectList: [], //存放区的列表

            formData: {
                addr: {
                    provinceCode: "",
                    cityCode: "",
                    countyCode: [],
                    streetCode: [],
                    communityCode: [],
                },
            },

            scopedList: [],
        }
    },
    methods: {
        addGridStatusFn(status) {
            if (!status && this.addModel.cancelGridTip) {
                // 清空新增的东西
                this.cleanNum()
                this.addModel.addGridStatus = status
            } else {
                this.cleanNum()
                this.addModel.addGridStatus = status
            }
        },
        // 新增按钮
        async addGrid() {
            this.cleanNum()
            this.addModel.addGridStatus = true
            await this.selectManageScopeByOrgCodeSelf()
            await this.getPulishData("CN", "provinceList", "province")
        },
        saveGrid() {
            var orgCodes = this.getOrgCode()
            if (orgCodes != "") {
                this.saveStaffOrgScope(orgCodes)
            } else {
                this.$Message.warning({
                    content: "请选择之后再进行保存",
                    background: true,
                })
                return
            }
        },
        hadlePageSize(val) {
            this.pageSize = val.pageSize
            this.curPage = val.page
        },
        async getList() {
            await this.$.ajax({
                url: proxy["/gx"].target + "/pc/staff/getStaffOrgScopeList",
                type: "GET",
                headers: {
                    Authorization: window.sessionStorage.getItem("accessToken"),
                },
                data: {
                    custGlobalId: this.custGlobalId,
                    orgCodeSelf: this.orgCodeSelf,
                },
                success: res => {
                    if (res && res.dataList) {
                        this.tableData = res.dataList
                    }
                },
            })
        },
        async saveStaffOrgScope(orgCodes) {
            await this.$.ajax({
                url: proxy["/gx"].target + "/pc/staff/saveStaffOrgScope",
                type: "POST",
                headers: {
                    Authorization: window.sessionStorage.getItem("accessToken"),
                },
                data: {
                    custGlobalId: this.custGlobalId,
                    orgCodeSelf: this.orgCodeSelf,
                    orgCodes,
                },
                success: res => {
                    if (res && res.code == 200) {
                        this.$Message.success({
                            content: "设置成功",
                            background: true,
                        })
                        this.addGridStatusFn(false)
                        this.getList()
                    } else {
                        this.$Message.error({
                            content: res.desc,
                            background: true,
                        })
                        return
                    }
                },
            })
        },

        async changeProvince(code) {
            this.cityDisabled = false
            await this.getPulishData(code, "cityList", "city")

            this.formData.addr.cityCode = ""
            this.formData.addr.countyCode = []
            this.formData.addr.streetCode = []
            this.formData.addr.communityCode = []
        },
        // 选择城市回调事件
        async changeCity(code) {
            this.regionDisabled = false
            await this.getPulishData(code, "regionList", "region")
            this.formData.addr.countyCode = []
            this.formData.addr.streetCode = []
            this.formData.addr.communityCode = []
        },
        // 选择区的回调事件
        async changeRegion(code) {
            this.streeList = []
            this.projectList = []
            this.stree = []
            this.project = []
            this.streeDisabled = true
            this.projectDisabled = true
            if (code && code.length == 1) {
                this.streeDisabled = false
                await this.getPulishData(code[0], "streeList", "stree")
            }
        },
        // 选择街道的回调事件
        async changeStree(code) {
            this.projectDisabled = true
            this.formData.addr.communityCode = []
            if (code && code.length == 1) {
                this.projectDisabled = false
                await this.getPulishData(code[0], "projectList", "project")
            }
        },
        // 获取发布范围列表
        async getPulishData(code, list) {
            await this.$.ajax({
                url: proxy["/orgzz"].target + "/pc/organization/selectOrganizationByOrgCode",
                type: "GET",
                headers: {
                    Authorization: window.sessionStorage.getItem("accessToken"),
                },
                data: {
                    orgCode: code,
                },
                success: res => {
                    if (res.code == 200) {
                        var isTrue = false
                        if (this.scopedList && this.scopedList.length && res.dataList.length && !isTrue) {
                            this[list] = res.dataList
                                .map(item => {
                                    if (this.scopedList.indexOf(item.orgCode) != -1) {
                                        isTrue = true
                                        return {
                                            value: item.orgPath,
                                            label: item.orgName,
                                            orgLevel: item.orgLevel,
                                            code: item.orgCode,
                                            parentId: item.parentId,
                                            orgId: item.orgId,
                                        }
                                    }
                                })
                                .filter(item => {
                                    return item !== undefined
                                })
                        }

                        if (!isTrue) {
                            console.log('如果没有进入');
                            this[list] = res.dataList.map(item => {
                                return {
                                    value: item.orgPath,
                                    label: item.orgName,
                                    orgLevel: item.orgLevel,
                                    code: item.orgCode,
                                    parentId: item.parentId,
                                    orgId: item.orgId,
                                }
                            })
                        }
                    }
                },
            })
        },
        getOrgCode() {
            if (this.formData.addr.communityCode && this.formData.addr.communityCode.length) {
                return this.formData.addr.communityCode.join(",")
            } else if (this.formData.addr.streetCode && this.formData.addr.streetCode.length) {
                return this.formData.addr.streetCode.join(",")
            } else if (this.formData.addr.countyCode && this.formData.addr.countyCode.length) {
                return this.formData.addr.countyCode.join(",")
            } else if (this.formData.addr.cityCode && this.formData.addr.cityCode != "") {
                return this.formData.addr.cityCode
            } else return this.formData.addr.provinceCode
        },
        cleanNum() {
            this.$nextTick(() => {
                this.formData = {
                    addr: {
                        provinceCode: "",
                        cityCode: "",
                        countyCode: [],
                        streetCode: [],
                        communityCode: [],
                    },
                }
                this.province = ""
                this.provinceList = [] //存放城市列表
                this.city = ""
                this.cityDisabled = true
                this.cityList = [] //存放城市列表
                this.region = ""
                this.regionDisabled = true
                this.regionList = [] // 存放区的列表
                this.stree = ""
                this.streeDisabled = true
                this.streeList = [] //存放街道的列表
                this.project = ""
                this.projectDisabled = true
                this.projectList = [] //存放区的列表
            })
        },
        // 删除
        async deleteStafforgScope(orgCode) {
            await this.$.ajax({
                url: proxy["/gx"].target + "/pc/staff/deleteStaffOrgScope",
                type: "GET",
                headers: {
                    Authorization: window.sessionStorage.getItem("accessToken"),
                },
                data: {
                    custGlobalId: this.custGlobalId,
                    orgCodeSelf: this.orgCodeSelf,
                    orgCode,
                },
                success: res => {
                    if (res && res.code == 200) {
                        this.$Message.success({
                            content: "删除成功",
                            background: true,
                        })
                        this.getList()
                    } else {
                        this.$Message.error({
                            content: res.desc,
                            background: true,
                        })
                        return
                    }
                },
            })
        },
        // 获取机构权限
        async selectManageScopeByOrgCodeSelf() {
            await this.$.ajax({
                url: proxy["/gx"].target + "/pc/organizationScope/selectManageScopeByOrgCodeSelf",
                type: "GET",
                headers: {
                    Authorization: window.sessionStorage.getItem("accessToken"),
                },
                data: {
                    orgCodeSelf: this.orgCodeSelf,
                },
                success: res => {
                    if (res && res.code == 200) {
                        this.scopedList = []
                        if (res.dataList && res.dataList.length) {
                            res.dataList.map(item => {
                                if (item.provinceCode != "") {
                                    this.scopedList.push(item.provinceCode)
                                }
                                if (item.cityCode && item.cityCode != "") {
                                    this.scopedList.push(item.cityCode)
                                }
                                if (item.zoneCode && item.zoneCode != "") {
                                    this.scopedList.push(item.zoneCode)
                                }
                                if (item.streetCode && item.streetCode != "") {
                                    this.scopedList.push(item.streetCode)
                                }
                                if (item.communityCode && item.communityCode != "") {
                                    this.scopedList.push(item.communityCode)
                                }
                            })
                            this.scopedList = Array.from(new Set(this.scopedList))
                        }
                    }
                },
            })
        },
    },
    created() {
        this.getList()
    },
}
</script>

<style scoped lang="less">
</style>
